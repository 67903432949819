@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700'); // feel free to change this font type at any momment.

$primary: #3c3c38; /* MAIN COLOR */
$secondary: #484d5b; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #d5d5d5;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


@import url('https://fonts.googleapis.com/css?family=Catamaran|Poppins|Raleway|Titillium+Web');
// font-family: 'Raleway', sans-serif;
// font-family: 'Poppins', sans-serif;
// font-family: 'Titillium Web', sans-serif;
// font-family: 'Catamaran', sans-serif;


// body {
// 	font-size: 1.5em;
// <
// // 	h1,h2,h3,h4,h5,h6 {
// // 		font-size:2em;
// // 	}
// // }

nav {
	z-index: 10;
};

.navbar-default {
    background-color:#38d4d1;
    border-color: #e7e7e7;
}


.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
 

	@media(max-width: 767px){
		height: 70px; // nav height mobile,(feel free to change this values as you please)		
	}
}

.navbar .navbar-nav {
> li > a {
			border-radius: 5px;
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.5em 1em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk,20%);
		text-decoration: none;

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
.section-a {
	padding: 60px;
	margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'

}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;
	
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}
		
		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}		
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}	
}


.logo {
  max-width: 400px;
  width: 100%;

	@media(max-width: 767px) {
		padding-top: 5px;
		max-width: 250px;
	}

	@media(max-width: 350px) {
		padding-top: 10px;
		max-width: 200px;
	}

    .navbar-header a {
        float: left;
    }
}

/* .banner {
    background: url(../img/banner.jpg) no-repeat;
    background-size: cover;
} */

.banner-v1 {
    background: url(../img/banner/banner_v1.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v2 {
    background: url(../img/banner/banner_v2.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v3 {
    background: url(../img/banner/banner_v3.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v4 {
    background: url(../img/banner/banner_v4.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v5 {
    background: url(../img/banner/banner_v5.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v6 {
    background: url(../img/banner/banner_v6.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v7 {
    background: url(../img/banner/banner_v7.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v8 {
    background: url(../img/banner/banner_v8.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v9 {
    background: url(../img/banner/banner_v9.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.banner-v10 {
    background: url(../img/banner/banner_v10.jpg) center center no-repeat;
    -moz-background-size: cover;
    background-size: cover
}

.static-banner {
    position: relative;
    width: 100%;
    height: 780px;
    display: table
}

.static-banner .banner-content {
    display: table-cell;
    vertical-align: middle
}

.static-banner .banner-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5)
}

.static-banner .banner-content p {
    font-size: 24px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}

.static-banner-full {
    position: relative;
    width: 100%;
    height: 100vh;
    display: table
}

.static-banner-full .banner-content {
    display: table-cell;
    vertical-align: middle
}

.static-banner-full .banner-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5)
}

.static-banner-full .banner-content p {
    font-size: 24px;
    color: #fff;
    line-height: 31px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}


@media (max-width:480px) {
    .static-banner .banner-content h1, .static-banner-full .banner-content h1 {
        font-size: 48px
    }
}

.btn-banner {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 32px;
    border: 2px solid #fff;
    display: inline-block;
    text-align: center;
    margin-top: 50px;
    background: rgba(0, 0, 0, .05)
}

.btn-banner i {
    color: #fff
}

.btn-banner:hover {
	background-color: rgba(255, 255, 255, .3)
}

.video-banner {
    position: relative;
    background: #000
}

.fullscreen-bg__video {
    width: 100%;
    height: 100%
}

.video-content {
    position: absolute;
    top: 30%;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: table-cell;
    vertical-align: middle
}

.video-content h1 {
    font-size: 75px;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5)
}

.video-content p {
    font-size: 24px;
    color: #fff;
    margin-bottom: 50px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3)
}

@media (max-width:568px) {
    .video-content h1 {
        font-size: 26px
    }
    .video-content p {
        font-size: 16px
    }
    .video-content .btn-banner {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 21px
    }
}

.flexslider .slides>li {
    position: relative
}

.flex-caption {
    bottom: 30px;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    margin: 0;
    padding: 25px 25px 25px 30px;
    position: absolute;
    left: 30px;
    width: 295px
}

.flex-caption h3 {
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-transform: uppercase
}

.flex-caption p {
    font-size: 14px!important;
    line-height: 22px;
    font-weight: 300;
    color: #fff
}

.color-white {
    color: #fff!important
}

.color-black {
    color: #3a3a3a
}


.bannercontainer {
    width: 100%;
    position: relative;
    padding: 0
}

.bannercontainer .banner {
    width: 100%;
    position: relative;
}

.couple-lucky {
  background: url('../img/banner2.jpg') no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
}

.img-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #2e8def;
    opacity: .3;
}

.clearfix {
    clear: both
}

.padTop {
  padding-top: 2em;
}

.padBot {
  padding-bottom: 2em;
}

.roundit {
  border-radius: 5px;
}

.no_bullet {
  list-style-type: none;
}

.hero-about {
  background-color: #38d4d1;
  padding: 75px 0px; 

   	h2 {
 		font-family: 'Titillium Web', sans-serif;
 		text-shadow: 1px 1px #999;
 		font-size: 30px;
 	}

 	p { 
 		font-family: 'Poppins', sans-serif;
 		font-size: 15px;
 	
		@media (max-width: 768px){
			font-size: 13px;
		}
 	}
}

.hero-divisions {
	background-color: #38d4d1;

 	h2 {
 		font-family: 'Catamaran', sans-serif;
 		 text-shadow: 1px 1px #999;
 		font-size: 25px;
 	}

 	p { 
 		font-family: 'Poppins', sans-serif;
 		font-size: 15px;
 	
		@media (max-width: 768px){
			font-size: 13px;
		}
 	}
 }

.hero-img {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #40e7ff 100%), url(../img/banner2.jpg);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #40e7ff 100%), url(../img/banner2.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  padding: 250px 0px;
  color: #333;

    h4 {
    	font-family: 'Titillium Web', sans-serif;
    	 text-shadow: 2px 2px #666;
    	color: #fff;
    	font-weight: bolder;
    	font-size: 30px;
   	}

  	@media (max-width: 1366px) {
    background-attachment: unset;
    }

    @media (max-width: 767px) {
    background-attachment: unset;
    padding: 50px 0px;

    	h4 {
    		font-size: 15px;
   		}
    }

  	@media (max-width: 320px) {
    background-attachment: unset;
    }

}

.info ul {
  display: inline-block;
  -webkit-padding-start: 0px;
  -moz-padding-start: 0px;
  padding-start: 0px;
}

.hero-contact{
	
	h3 {
 		font-family: 'Catamaran', sans-serif;
 		    text-shadow: 1px 1px #666;
 		font-size: 25px;
 	}

	p { 
 		font-family: 'Poppins', sans-serif;
 		font-size: 15px;
 	
		@media (max-width: 768px){
			font-size: 13px;
		}
	}
}

.info {

	text-align: center;
  		a  {

  		color: #333;

		&:hover {
			color: #38d4d1;
			text-decoration: none;
		}
  	}
}
